/* You can add global styles to this file, and also import other style files */

body {
    margin: 0 !important;
}

body .p-component,
.letra-telcel
{
  font-family: "Source Sans Pro", sans-serif;
}
.max-width-500 {
    max-width: 600px;
  }
.bgContainerBlue{
    background-color: #083364;
}
.app-text-blue{
    color: #083364;
}
.logoRegisterContainer {
  background-image: url("/assets/images/amigo_logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.solicitud-card {
  border-radius: 1em !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: 0px -3px 5px 0px rgba(0,0,0,0.75) !important;
  -webkit-box-shadow: 0px -3px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px -3px 5px 0px rgba(0,0,0,0.75);
}
.amigo_telcel_logo {
  border-radius: 10px;
}
.p-chip-text {
  font-weight: bold;
}
.filter-blue {
  filter: invert(95%) sepia(100%) saturate(2400%) hue-rotate(204deg) brightness(94%) contrast(92%);
}
.letra-blanca {
  color: white;
}
.h-100vh{
  height: 100vh;
}

.text-center{
  text-align: center;
  padding: 0px 12px;
}
